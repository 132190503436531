.table-body {
  .table-row,
  .table-row-extra {
    height: 6.4rem;
    box-shadow: inset 0px -1px 0px $color-gray3;
    outline:none;
    &:focus, &:hover {
      outline: $shadow-accessible-outline;
    }
  }

  .table-row {
    &.expanded,
    &.collapsed
    {
      cursor: pointer;
    }

    &.odd {
      background-color: $color-gray1;
    }
    &.even {
      background-color: $color-gray2;
    }
  }

  .table-row-extra {
    background-color: $color-brandtertiary-highlight;
  }
}
