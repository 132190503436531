$selectMenuTransitionDelay: 0.3s ;

.selectmenu-component {
  box-sizing: content-box;
  cursor:pointer;
  height:4.8rem;
  padding:0 1.5rem;
  display:flex;
  align-items: center;
  justify-content: space-between;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.6rem;
  line-height: 1.6rem;
  letter-spacing: 0.5px;
  user-select: none;
  transition: background-color $selectMenuTransitionDelay ease-in-out, color $selectMenuTransitionDelay ease-in-out ;
  &-label{
    cursor:pointer;
    padding-right:2rem;
  }
  &__icon{
    transition: transform $selectMenuTransitionDelay ease-in-out;
    max-width:2.8rem;
    max-height:2.8rem;
    .popper__toggle--open & { 
      transform: scaleY(-1);
      filter: FlipV;
    }
    .popper__toggle--closed & {
      transform: scaleY(1);
      filter: FlipV;
    }
  }

  &-large {
    border: 0.2rem solid;
    border-radius: 10rem; // round corners to make button look like a pill.
    padding-top:1px; // font artifact makes the button label look too high.
    .popper__toggle--closed & {
      background: $color-white;
      color: $color-brandprimary;
      border-color: $color-brandprimary;
      svg{
        color: $color-brandprimary;
      }
    }
    .popper__toggle--open & {
      background: $color-positive;
      color: $color-white;
      border-color: $color-positive;
      svg{
        color:$color-white;
      }
    }
    .popper__toggle--disabled & {
      background: $color-gray1;
      color: $color-gray3;
      border-color: $color-gray3;
      svg{
        color:$color-gray3;
      }
    }
  }
  &-medium, &-small {
    .popper__toggle--closed & {
      color: $color-brandsecondary;
      svg{
        color: $color-brandsecondary;
      }
    }
    .popper__toggle--open & {
      color: $color-positive;
      svg{
        color:$color-positive;
      }
    }
    .popper__toggle--disabled & {
      color: $color-gray3;
      svg{
        color:$color-gray3;
      }
    }
  }
}