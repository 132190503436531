 
  .background-container-shadow{
    background: $color-white;
    box-shadow: 0px 4px 16px $shadow-black-lighter;
  }
  .background-container-screen{
    background: $color-gray2;
  }
  .background-container-semi-transparent{
    background: $color-white-semi-transparent;
    box-shadow: 0px 4px 16px $shadow-black-lighter;
  }

  .background-container-message{
    background: $color-gray1;
    border: 1px solid $color-gray3;
  }