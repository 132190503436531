.checkbox-container {
  box-sizing: content-box;
  display:inline-block;
  outline:none;
  &:focus, &:hover{
    outline:  $shadow-accessible-outline;
  }
}
.checkbox-label {
  position: relative;
  cursor: pointer;
  font-size: 1.6rem;
  line-height: 1.6rem;
  height: 1.6rem;
  width: 1.6rem;

  // default label text style
  &__text {
    font-size: 1.6rem;
    margin-left: 2.8rem;
    color: $color-brandsecondary;
    font-weight: 500;
  }

  &__left {
    margin-left: 0;
    margin-right: .6rem;
  }

  // hide the input field
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  // position and default style custom box
  .checkbox-custom {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 1.6rem;
    width: 1.6rem;
    background-color: transparent;
    border-radius: 2px;
    transition: all 0.3s ease-in-out;
    border: 2px solid $color-gray3;

    &__left {
      left: auto;
    }

    // checkmark styles
    &::after {
      position: absolute;
      content: "";

      // makes it fade to/from bottom left corner
      left: 1.2rem;
      top: 1.2rem;
      height: 0px;
      width: 0px;
      border: solid $color-gray3;
      border-radius: 2px;
      border-width: 0 2px 2px 0;
      transform: rotate(0deg) scale(0);
      opacity:1;
      transition: all 0.3s ease-in-out;
    }
  }

  // when input is checked apply styles to custom
  input:checked ~ .checkbox-custom {
    background-color: $color-positive;
    border-radius: 2px;
    transform: rotate(0deg) scale(1);
    opacity:1;
    border: 2px solid $color-positive;

    // checked style (checkmark)
    &::after {
      transform: rotate(45deg) scale(1);
      left: .5rem;
      top: 0;
      width: 4px;
      height: 11px;
      border: solid $color-white;
      border-width: 0 2px 2px 0;
      background-color: transparent;
      border-radius: 0;
    }
  }

  input:checked ~ .checkbox-label__text {
    color: black;
    font-weight: 900;
  }

  // disabled styles
  &.checkbox-label--disabled {
    cursor: default;

    // when selected and disabled have gray background
    input:checked ~ .checkbox-custom {
      background-color: $color-gray4;
      border: 2px solid $color-gray4;
    }

    span.checkbox-label__text {
      color: $color-gray4;
    }
  }
}
