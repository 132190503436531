.togglebutton-component {
  display: inline-flex;
  align-items: center;

  .togglebutton-component-label {
    font-size: 1.4rem;
    line-height: 1.6rem;
    font-style: italic;
    color: $color-gray5;
  }

  &-labelleft {
    flex-flow: row nowrap;
    & .togglebutton-component-label {
      padding-right: 1.6rem;
    }
  }
  &-labelright {
    flex-flow: row-reverse nowrap;
    & .togglebutton-component-label {
      padding-left: 1.6rem;
    }
  }
  &-labeltop {
    flex-flow: column nowrap;
    & .togglebutton-component-label {
      padding-bottom: 1.6rem;
    }
  }
  &-labelbottom {
    flex-flow: column-reverse nowrap;
    & .togglebutton-component-label {
      padding-top: 1.6rem;
    }
  }
}

// Inspired by https://codepen.io/mallendeo/pen/eLIiG
.togglebutton-component-input {
  display: none;
  // add default box-sizing for this scope
  &,
  &:after,
  &:before,
  & *,
  & *:after,
  & *:before,
  & + .togglebutton-component-button {
    box-sizing: border-box;
    &::selection {
      background: none;
    }
  }

  + .togglebutton-component-button {
    outline: none;
    display: block;
    width: 6rem;
    height: 3.2rem;
    position: relative;
    cursor: pointer;
    user-select: none;
    &:after,
    &:before {
      position: relative;
      display: block;
      content: "";
      width: 50%;
      height: 100%;
    }
    &:after {
      left: 0;
    }
    &:before {
      display: none;
    }

    background: $color-gray1;
    border-radius: 2.8rem;
    padding: 0.2rem;
    transition: all .4s ease;
    border: 0.1rem solid $color-gray2; // dark border around button
    &:after {
      border-radius: 50%;
      background: $color-gray1;
      transition:
        left .3s cubic-bezier(
          0.175, 0.885, 0.320, 1.275
        ),
        padding .3s ease, margin .3s ease;
      box-shadow:
        0 0 0 0.1rem rgba(0,0,0,.1),
        0 0.4rem 0 rgba(0,0,0,.08);
    }

    &:hover:after {
      will-change: padding;
    }

    &:active {
      box-shadow: inset 0 0 0 0.2rem $color-gray2; // little lip on right of button when clicked.
      &:after {
        padding-right: .8rem; // makes button grow a little larger when clicked
      }
    }
  }

  &:disabled + .togglebutton-component-button {
    cursor: default;
    background: $color-gray3;
  }
  &:checked + .togglebutton-component-button {
    background: $color-positive;
    &:after {
      left: 50%;
    }
    &:active {
      box-shadow: none;
      &:after {
        margin-left: -.8rem; // makes button grow a little larger when clicked
      }
    }
  }

  // if disabled and checked
  &:disabled + .togglebutton-component--disabled-selected {
    cursor: default;
    pointer-events: none;
    opacity: 0.3;

    &:after {
      background-color: $color-gray2;
    }
  }
}
