$arrow-size-top-bottom: 8px;
$arrow-size-left-right: 6px;

@mixin tooltipBackground($background-color) {
  &.place-top {
    &:after {
      border-top-width: $arrow-size-top-bottom;
      border-top-color: $background-color;
    }
  }
  &.place-bottom {
    &:after {
      border-bottom-width: $arrow-size-top-bottom;
      border-bottom-color: $background-color;
    }
  }
  &.place-left {
    &:after {
      border-left-width: $arrow-size-left-right;
      border-left-color: $background-color;
    }
  }
  &.place-right {
    &:after {
      border-right-width: $arrow-size-left-right;
      border-right-color: $background-color;
    }
  }
}

@mixin tooltipBorder($border-color) {
  border: 1px solid $border-color;
  &.place-top {
    &:before {
      border-top: $arrow-size-top-bottom solid $border-color;
    }
  }
  &.place-bottom {
    &:before {
      border-bottom: $arrow-size-top-bottom solid $border-color;
    }
  }
  &.place-left {
    &:before {
      border-left: $arrow-size-left-right solid $border-color;
    }
  }
  &.place-right {
    &:before {
      border-right: $arrow-size-left-right solid $border-color;
    }
  }
}

.__react_component_tooltip {
  z-index: $z-index-tooltip;
  box-sizing: content-box;
  align-items: center;
  border-radius: 2.875rem;
  border: 1px solid $color-brandprimary;
  display: flex;
  line-height: 1.5rem;
  max-width: 18rem;
  padding: 1.3rem 2rem;

  &.show { opacity: 1; }

  .tooltip__icon {
    padding: 0 0.8rem 0 2.4rem;
    text-align: left;
  }

  &.type-dark {
    background: $color-brandtertiary-highlight;
    color: $color-brandsecondary;
    @include tooltipBackground($color-brandprimary);
    &.border {
      @include tooltipBorder($color-brandprimary);
    }
  }

  // Place
  &.place-top {
    &:after {
      bottom: -9px;
    }
    &:before {
      border-color: $color-brandtertiary-highlight transparent transparent transparent;
      z-index: 1;
      margin-left: -10px;
      top: 100%;
      margin-left: -.8rem;
      border-width: 8px;
      border-style: solid;
    }
  }

  &.place-bottom {
    &:after {
      top: -8px;
    }
    &:before {
      border-color:  transparent transparent $color-brandtertiary-highlight transparent;
      border-style: solid;
      border-width: 8px;
      z-index: 1;
      top: -14px;
      left: 52%;
      margin-left: -10px;
    }
  }

  &.place-left {
    &:before {
      border-color:  transparent transparent transparent $color-brandtertiary-highlight;
      border-style: solid;
      border-width: 8px;
      z-index: 1;
      border-top: 6px solid transparent;
      border-bottom: 6px solid transparent;
      right: -13px;
      top: 50%;
      margin-top: -5px;
    }
  }

  &.place-right {
    &:before {
      border-color: transparent $color-brandtertiary-highlight transparent transparent;
      z-index: 1;
      left: -4px;
      margin-top: -7px;
      margin-left: -.8rem;
      border-width: 8px;
      border-style: solid;
    }
  }
}

.tooltip-component {
  z-index: $z-index-tooltip-component;
  display: inline-flex;
  outline:none;
  &:hover, &:focus{
    outline: $shadow-accessible-outline;
  }
}