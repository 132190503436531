.input-group {

  &__label-container {
    display: flex;
    align-items: center;
    margin-bottom: .4rem;

    &__label {
      font-weight: 500;
      font-size: 1.4rem;
      line-height: 1.8rem;
      color: $color-brandsecondary;
      margin-right: 0.8rem;

      &--disabled {
        color: $color-gray4;
      }

      &--hidden {
        display: none;
      }
    }
  }

  &__input_container {
    display: flex;
    border: 1px solid $color-gray3;
    background: $color-gray1;

    &--focused {
      border: 1px solid $color-positive;
      background: $color-white;
      outline: none;
    }

    &--lg {
      height: 4.8rem;
      padding: 0 1.6rem;
    }

    &--sm {
      height: 3.2rem;
      padding: 0 1.6rem;
    }


    &--invalid {
      border: 1px solid $color-error;
      &:focus {
        border: 1px solid $color-error;
      }
    }

    &--disabled {
      background-image: repeating-linear-gradient(
        45deg,
        transparent,
        transparent .5rem,
        rgba(255,255,255,.5) .5rem,
        rgba(255,255,255,.5) 1rem
      );

      &::placeholder {
        visibility: hidden;
      }
    }

    &__icon {
      flex: 0 1 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        margin-right: .8rem;
      }
    }

    // default input field styles
    &__input {
      flex: 1 0 auto;
      font-size: 1.6rem;
      background: $color-gray1;
      color: $color-black;
      border: none;
      background: transparent;

      &:-ms-input-placeholder, &::placeholder { /* IE 10 & IE 11 */
        color: $color-gray5;
      }

      &--disabled {
        color: $color-gray4;

        &:-ms-input-placeholder, &::placeholder { /* IE 10 & IE 11 */
          color: $color-gray4;
        }
      }

      &:focus {
        outline: none;
      }

    }

    &__light {
      background: $color-white;
    }
  }

  &__error_container {

    &__text {
      padding-top: 0.4rem;
    }

  }
}
