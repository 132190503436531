.iconselector-component{
  display: flex;
  flex-flow: row;
  cursor:pointer;
  &__childwrapper{
    display: flex;
    align-items:center;
    justify-content:center;
    margin:0 0.8rem;
    height:4.8rem;
    width:4.8rem;
    border-radius:50%;
    svg{
      max-width:2.4rem;
      max-height:2.4rem;
    }
    border:none;
    background:none;
    outline:none;
    &:hover, &:focus{
      background: $color-gray2;
    }
    &--selected{
      background: $color-gray2;
      svg{
        color:$color-positive;
      }
    }
    &--disabled{
      svg{
        color:$color-gray3;
      }
      &:hover{
        background: $color-white;
        svg{
          color:$color-gray3;
        }
      }
    }
  }
}