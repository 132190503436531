.sidenav {
  box-sizing: content-box;
  display: flex;
  flex-direction: column;
  background-color: $color-gray5;
  width: 7.2rem;

  /* subtracts height of topbar from the total view height
  to stop bottom scroll, total height (sidenav + topbar) is
  100% of screen. min-height allows it to grow if needed. */
  min-height: calc(100vh - 5.6rem);

  .nav-item__active {
    background-color: $color-brandsecondary;
    box-shadow: inset 0px 1px 0px $color-gray4, inset 0px -1px 0px $color-gray4, inset 6px 0px 0px $color-brandprimary;
    width:100%;
  }

  .nav-item a {
    display: block;
    text-align: center;
    cursor: pointer;
    height: 7.2rem;
    width: 7.2rem;

    transition: all 0.2s ease;
        
    // svg handles font awesome icons
    // span handles custom icons container
    svg, span {
      color: $color-gray1;
      font-size: 3.2rem;
      height: 7.2rem;
      line-height: 7.2rem;
    }
  
    outline: none;
    &:hover, &:focus {
      background-color: $color-brandsecondary;
    }
  }
  
  // grows middle div to keep dashboard icon on bottom
  .nav-item__spacer {
    flex-grow: 1;
  }
}
