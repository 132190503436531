.pagination-component{
  box-sizing: content-box;
  background: $color-gray1;
  border-radius: 2.4rem;
  border:1px solid $color-gray3;
  height:4.8rem;
  display:inline-flex;
  flex-flow:row nowrap;
  justify-content:center;
  align-items: center;
  user-select: none;

  &__container{
    display:flex;
    flex-flow:row nowrap;
    justify-content:center;
    align-items: center;
  }
  &__pagebutton, &__caret, &__chevron{
    cursor: pointer;
    line-height:normal;
    height:100%;
    margin: 0 2px;
    min-width:2rem;
    min-height: 2rem;
    padding:0.4rem;
    border-radius:50%;
    outline: none;
  }
  &__caret svg, &__chevron svg{
    margin:1px 0 0 0;
    color: $color-brandprimary;
  }
  &__caret:hover, &__chevron:hover, &__caret:focus, &__chevron:focus{
    background: $color-brandprimary;
  }
  &__caret:hover svg, &__chevron:hover svg, &__caret:focus svg, &__chevron:focus svg{
    color:$color-white;
  }
  &__caret--disabled, &__chevron--disabled{
    cursor: inherit; // has to be on non-hover entry otherwise the cursor flickers
  }
  &__caret--disabled svg, &__chevron--disabled svg{
    color: $color-gray3;
  }
  &__caret--disabled:hover, &__chevron--disabled:hover{
    background: transparent;
  }
  &__caret--disabled:hover svg, &__chevron--disabled:hover svg{
    color: $color-gray3;
  }

  &__pagebutton{
    color: $color-black;
    text-decoration: none;
    text-align:center;
  }
  &__pagebutton:hover, &__pagebutton:focus{
    background: $color-brandprimary;
    color:$color-white;
  }
  &__pagebutton--selected, &__pagebutton--selected:hover{
    cursor: inherit;
    color:$color-white;
    background: $color-brandprimary;
  }
  &__pagebutton--disabled, &__pagebutton--disabled:hover{
    cursor: inherit;
    color:$color-gray3;
  }
}