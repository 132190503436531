.kubra-ux-text-area {

  &__label_container {
    display: flex;
    align-items: center;
    margin-bottom: .4rem;

    &__label {
      font-weight: 500;
      font-size: 1.4rem;
      line-height: 1.8rem;
      color: $color-brandsecondary;
      margin-right: 0.8rem;

      &--disabled {
        color: $color-gray4;
      }

    }
  }

  &__textarea_container {

    &__textarea {
      font-size: 1.6rem;
      line-height: 2rem;
      width: 100%;
      resize: none;
      color: $color-black;
      background: $color-gray1;
      border: 1px solid $color-gray3;
      padding: 1.8rem 1.6rem 1.4rem 1.6rem;

      &:focus {
        border: 1px solid $color-positive;
        background: $color-white;
        outline: none;
      }

      &--invalid {
        border: 1px solid $color-error;
        &:focus {
          border: 1px solid $color-error;
        }
      }

      &--disabled {
        background-image: repeating-linear-gradient(
          45deg,
          transparent,
          transparent .5rem,
          rgba(255,255,255,.5) .5rem,
          rgba(255,255,255,.5) 1rem
        );

        color: $color-gray4;

        &:-ms-input-placeholder, &::placeholder { /* IE 10 & IE 11 */
          color: $color-gray4;
        }
      }
    }
  }

  &__light {
    background: $color-white;
  }
}