@mixin arrow-style {
  width: 0;
  height: 0;
  position: absolute;
  border-style: solid;
}

.popover {
  box-sizing: content-box;

  &__content {
    border-radius: .8rem;
    max-width: 360px;
    min-width: 20rem;
    background-color: $color-brandsecondary;
    margin: .8rem;
    padding: 2rem 2.4rem;
    z-index: $z-index-poppermenu-dropdown;
  }
  &__ref-element{
    &:hover, &:focus{
      outline: $shadow-accessible-outline;
      color:$color-brandprimary;
    }
  }


  // BOTTOM PLACEMENTS
  div[data-placement="bottom"],
  div[data-placement="bottom-start"],
  div[data-placement="bottom-end"] {
    .popover__arrow {
      @include arrow-style;
      border-width: 0 .8rem .8rem .8rem;
      top: -.8rem;
      margin: 0 1.6rem;
      border-color: transparent transparent $color-brandsecondary transparent;
    }
  }

  // TOP PLACEMENTS
  div[data-placement="top"],
  div[data-placement="top-start"],
  div[data-placement="top-end"] {
    .popover__arrow {
      @include arrow-style;
      border-width: .8rem .8rem 0 .8rem;
      bottom: -.8rem;
      margin: 0 1.6rem;
      border-color: $color-brandsecondary transparent transparent transparent;
    }
  }

  // RIGHT PLACEMENTS
  div[data-placement="right"],
  div[data-placement="right-start"],
  div[data-placement="right-end"] {
    .popover__arrow {
      @include arrow-style;
      border-width: .8rem .8rem .8rem 0;
      left: -.8rem;
      margin: 1.6rem 0;
      border-color: transparent $color-brandsecondary transparent transparent;
    }
  }

  // LEFT PLACEMENTS
  div[data-placement="left"],
  div[data-placement="left-start"],
  div[data-placement="left-end"] {
    .popover__arrow {
      @include arrow-style;
      border-width: .8rem 0 .8rem .8rem;
      right: -.8rem;
      margin: 1.6rem 0;
      border-color: transparent transparent transparent $color-brandsecondary;
    }
  }
}
