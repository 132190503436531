.modal-alert {
  box-sizing: content-box;
  &__content {
    display: flex;
    flex-direction: column;
    width: 44.4rem;
    background-color: $color-brandsecondary;
    padding: 2.4rem;
    border-radius: 8px;
    margin: 9.3rem auto auto auto;
  }

  &__heading {
    flex: 0 1 auto;
    color: $color-white;
    text-align: left;
    font-size: 2rem;
    font-weight: bold;
  }

  &__message {
    flex: 1 0 auto;
    text-align: left;

    p {
      color: $color-white;
    }
  }

  &__buttons {
    flex: 0 1 auto;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-top: 2rem;

    &__cancel {
      color: $color-white;

      &:hover,
      &:active,
      &:focus {
        color: $color-brandtertiary-highlight;
      }
    }

    .button-component {
      margin-left: 3rem;
    }

  }
}