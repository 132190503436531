.table-head {
  background-color: $color-white;

  .table-row {
    height: 48px;
    min-height: 48px;
    box-shadow: inset 0px -1px 0px $color-gray3, inset 0px 1px 0px $color-gray3;

    .expand-spacer {
      // icon width + left padding width
      width: 2.4rem + 2.4rem;
    }

  }

}
