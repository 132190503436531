// 1 REM = 10px;
html { 
  font-size: 10px; 
  font-family: $font-family;
}

body {
  font-size: 1.6rem; 
  line-height: 2.4rem; 
  font-weight: normal;
  color: $color-black;
}

p, .p {
  font-size: 1.4rem;
  line-height: 1.8rem;
}
.small, small {
  font-size: 1.4rem;
  line-height: 1.8rem;
}
.strong, strong, b {
  font-weight: 700;
}
.tiny {
  font-size: 1.2rem;
  line-height: 1.8rem;
}
.callout, .callout a {
  color: $color-positive;
  font-size: 1.4rem;
  line-height: 1.8rem;
}
.error, .error a {
  color: $color-error;
  font-size: 1.4rem;
  line-height: 1.8rem;
}

h1, h2, h3, h4, h5,
.h1, .h2, .h3, .h4, .h5 {
  color: $color-brandsecondary;
}

h1, .h1 {
  font-size: 2.4rem; 
  line-height: 3.2rem; 
  font-weight: normal;
}

h2, .h2 {
  font-size: 2.0rem; 
  line-height: 2.4rem; 
  font-weight: 500;
}

h3, .h3 {
  font-size: 1.8rem; 
  line-height: 2.2rem; 
  font-weight: 900;
}

h4, .h4 {
  font-size: 1.6rem; 
  line-height: 2.0rem; 
  font-weight: 900;
}

h5, .h5 {
  font-size: 1.4rem; 
  line-height: 1.8rem;
  font-weight: 500;
}

a, a.link-primary {
  outline: none;
  color: $color-brandprimary;
  text-decoration: underline;
}
a.link-secondary {
  color: $color-positive;
}
a:hover, a.link--hover, a:focus {
  color: $color-brandsecondary;
  text-decoration: underline;
}
a:focus{
  outline: $shadow-accessible-outline;
}
a:visited, a.link--visited {
  color: $color-gray4;
  text-decoration: underline;
}
