.kubra-ux-multi-tag-dropdown {
  display: inline-flex;
  flex-direction: column;
  position: relative;
  width: 34rem;

  &__search {
    flex: 0 1 auto;

    .input-group__input_container__icon {
      font-size: 1.8rem;
      margin-right: .8rem;
    }
  }

  &__selected_tags {
    flex: 1 1 auto;
    // adding 1px to left and right prevent tags from shifting slightly when menu opens due to border on open menu
    padding: 0.8rem 0.9rem;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;

    &__tag_container {
      padding: 0.4rem;
    }
  }

  &__menu {
    z-index: $z-index-poppermenu-dropdown;
    position: absolute;
    background: $color-gray1;
    border: 1px solid $color-gray3;

    // remove border top so you can see green border (bottom) on search field
    border-top: none;

    &--with-label {
      // total height of input container and label
      top: 7rem;
    }

    &--without-label {
      // input container height
      top: 4.8rem;
    }


    right: 0;
    bottom: auto;
    left: 0;
    width: 34rem;

    &__selected_tags {
      flex: 1 1 auto;
      padding: 0.8rem;
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;

      &__tag_container {
        padding: 0.4rem;
      }
    }

    &__items {
      flex: 1 1 auto;

      &__item {
        cursor: pointer;
        height: 4.8rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
    }
  }

}