.listnav-component {
  box-sizing: content-box;
  user-select: none;

  &__listnavitem {
    background: $color-white;
    box-shadow: inset 0px -1px 0px $color-gray3;
    // minimum about of padding to not cover hover shadow with content
    padding-left: 0.6rem;
    height: 6.4rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: $color-brandsecondary;

    &--clickable {
      cursor: pointer;
    }

    outline:none;


    &:hover {
      background: $color-brandtertiary-highlight;
    }

    &--selected,
    &:focus {
      .listnav-component__listnavitem__text {
        font-weight: 900;
      }

      box-shadow: inset 6px 0px 0px $color-brandprimary, inset 0px -1px 0px $color-gray3;
      background: $color-brandtertiary-highlight;
    }

    &--disabled, &--disabled svg {
      color: $color-gray4;
    }

    &__icon {
      padding-right:40px;
    }

    &__icon svg {
      font-size:2.8rem;
    }

    &__text {
      line-height:2.4rem;
      max-height:2.4rem;
      font-weight: 500;
      overflow:hidden;
      padding-left: 4rem;
    }

  }
}