$color-brandprimary: #007FAD !default;
$color-brandsecondary: #36434E !default;
$color-brandtertiary-highlight: #DAEEF6 !default;
$color-black: #242424 !default;
$color-white: #fff !default;
$color-white-semi-transparent: #ffffff80;
$color-gray1: #F6F6F6 !default;
$color-gray2: #EAECEE !default;
$color-gray3: #CBD0D4 !default;
$color-gray4: #8697A7 !default;
$color-gray5: #536372 !default;
$color-positive: #018748 !default;
$color-warning: #EE7017 !default;
$color-error: #BE281E !default;
$color-popupoverlay: rgba($color-gray5, 0.7) !default;
$gradient_option: linear-gradient(134.76deg, #01A75A 14.62%, #0093C9 85.38%);
$yellow: #BF8808;

// kubra logo
$kubra-blue: #0093C9;
$kubra-green: #82BC00;
