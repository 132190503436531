.dropdown-group {
  z-index: $z-index-dropdown-group;
  position: relative;
  min-width: 20rem;

  display: flex;
  flex-direction: column;

  // when label placement is left, adjust flex properties
  &--label-placement-left {
    flex-direction: row;
    .dropdown-group__label-container {
      flex: 0 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .dropdown-group__control {
      flex: 1 0 auto;
    }
  }

  // apply padding to tooltip icon on label
  &__label-container {
    .dropdown-group__tooltip {
      padding: 0 0.8rem;
    }
  }

  // entire box style
  &__control {
    position: relative;
    border: 1px solid $color-gray3;
    background: linear-gradient(0deg, $color-gray2 0%, $color-gray1 100%);

    // border of select field green when expanded
    &--expanded {
      background: $color-white;
      border: 1px solid $color-positive;
    }

    &--disabled {
      background-image: repeating-linear-gradient(
        45deg,
        $color-gray1,
        $color-gray1 .5rem,
        rgba(255,255,255,.5) .5rem,
        rgba(255,255,255,.5) 1rem,
      );

      .dropdown-group__control__input-container__input {
        &::placeholder {
          visibility: hidden;
        }
        color: $color-gray4;
      }

      .dropdown-group__control__input-container__icon {
        background: $color-gray3;
        svg {
          color: $color-gray5;
        }
      }
    }

    &__input-container {
      display: flex;
      position: relative;
      cursor: pointer;

      &__input {
        flex: 1 1 auto;
        border: none;
        text-indent: 1.6rem;
        background: transparent;
        color: $color-black;
        font-size: 1.6rem;
        font-weight: 500;
        outline: none;
      }

      // position and style arrow icon
      &__icon {
        border-left: 1px solid $color-gray3;
        background: $color-brandtertiary-highlight;
        text-align: center;
        width: 4.8rem;
        height: 4.8rem;
        line-height: 4.8rem;

        &--expanded {
          border-left: 1px solid $color-positive;
        }

        svg {
          color: $color-brandprimary;
          font-size: 2.4rem;
          height: 100%;
        }
      }

    }

    &__menu {
      cursor: pointer;
      position: absolute;
      top: 4.9rem;
      width: 100%;
      background: $color-gray1;
      border: 1px solid $color-gray3;
      border-top: none;

      max-height: 24rem;
      overflow-y: scroll;

      &__item {
        display: block;
        cursor: pointer;
        margin: 0;
        padding: 1.6rem 0;
        color: $color-black;
        font-size: 1.6rem;

        &--selected {
          background: $color-brandtertiary-highlight;
          box-shadow: inset 6px 0px 0px $color-brandprimary;
          font-weight: 700;
        }
      }
    }

  }
}
