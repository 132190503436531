// variables
@import "./variables/index.scss";

@import "./normalize.scss";
@import "./fonts.scss";
@import "./typography.scss";
@import "./background.scss";

// elements
@import "./elements/index.scss";

// modules
@import './modules/index.scss';

// foundation
@import './foundation/index.scss'