
$z-index-dropdown-group: 99 !default;
$z-index-poppermenu-dropdown: 100 !default;

$z-index-menu-item: 1000 !default;

$z-index-tooltip-component: 1100 !default;
$z-index-tooltip: 1101 !default;

$z-index-modal-background: 1300 !default;
