.stepper-stepper{
  box-sizing: border-box;
  display: flex;
  position:relative;
  flex-flow:column;
}
.stepper-track{
  appearance: none;
  width:100%;
  height: calc( 2.6 * #{$slider-radius}); 
  display:flex;
  flex-flow:row ;
  align-items:center;
  justify-content:center;
  position:absolute;
  top:0;

  & .stepper-trackbar{
    height:1.6rem;
    flex:1; // width and flex-basis decide the size of the bars.
  }
  & .stepper-track__active{
    border-top-left-radius: 2rem;
    border-bottom-left-radius: 2rem;
    background: $gradient_option;
  }
  & .stepper-track__inactive{
    border-top-right-radius: 2rem;
    border-bottom-right-radius: 2rem;
    background: $color-gray2;
  }
}
.stepper-track__disabled{
  & .stepper-track__active, & .stepper-track__inactive{
    background:$color-gray2;
  }
}

.stepper-elements{
  display:flex;
  flex-flow:row ;
  align-content: center;
  justify-content: space-around;
  width:100%;
  z-index:1;
}

.stepper-element{
  appearance: none;
  display:flex;
  flex-flow:column nowrap;
  align-items: center;
  flex-basis: 100%; // make sure long step labels end up the same width as shorter step labels.
  &__clickable {
    cursor: pointer;
  }
  .stepper--step{
    padding-top:1.6rem;
    font-size:1.6rem;
    line-height:2.4rem;
    max-height:4.8rem; // only show total of two lines
    overflow: hidden;
  }
  outline:none;
  &:hover, &:focus{
    outline: $shadow-accessible-outline;
    color:$color-brandprimary;
  }
  &__active{
    color: $color-positive;
  }
  &__completed{
    color: $color-brandsecondary;
  }
  &__disabled{
    color: $color-gray4;
  }
}

.stepper-element-icon {
  background: $color-white;
  border: $slider-border-width solid $color-positive;
  border-radius: 100%;
  display: block;
  height: calc( 2 * #{$slider-radius} ); // 1.6 + twice the border = 3.2REM (32px)
  width: calc( 2 * #{$slider-radius} ); 
  margin: calc( #{$slider-icon-hover-expand-margin-allowance} * #{$slider-radius} );
  transition: $slider-icon-transition;
  border-color: $color-gray3;
  box-shadow: 0px 0px 0.8rem $shadow-black-lighter;

  .stepper-element__completed & {
    border-color: $color-black;
  }
  .stepper-element__active & {
    border-color: $color-positive;
    // We took the margin set in the non-hover state away and added to both the width and height.
    // It's *2 as margin is on both sides of element.
    height: calc( (2 * #{$slider-radius}) + ( #{$slider-icon-hover-expand-margin-allowance} * #{$slider-radius} *2 ));
    width: calc( (2 * #{$slider-radius}) + ( #{$slider-icon-hover-expand-margin-allowance} * #{$slider-radius} *2 ));
    margin: 0;
    box-shadow: 0px 0.4rem 0.8rem $shadow-black;
  }
  &.element__disabled & {
    border-color: $color-gray3;
  }
}
