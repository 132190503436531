.tabbar-component{
  box-sizing: content-box;
  display: flex;
  user-select: none;
  &__large &__tabbaritem-component{
    font-size: 1.6rem;
    margin: 0 1.2rem;
    padding:1.0rem 3.2rem; // Padding down by .2 in all directions due to border
    border: 0.2rem solid $color-gray3;
    &__disabled, &__disabled:hover{
      color:$color-gray4;
      background:$color-gray1;
      border-color:$color-gray3;
    }
  }
  &__small &__tabbaritem-component{
    font-size: 1.4rem;
    margin: 0 0.8rem;
    padding:0.8rem 2.4rem; // No border here
    border:none;
    &__disabled{
      color:$color-gray4;
      &:hover{
        color:$color-gray3;
      }
    }
  }
  &__tabbaritem-component{
    user-select: none;
    color:$color-gray5;
    background-color:$color-white;
    border-radius:10rem; // ensure pill shaped
    &__clickable{
      cursor:pointer;
    }
    &__label{
      user-select: none;
      line-height: 1.6rem;
      max-height:1.6rem;
      overflow:hidden;
      white-space:nowrap;
      text-align: center;
      letter-spacing: 0.05rem;
      text-transform: uppercase;
      font-weight: bold;
    }
    &__selected{
      color:$color-white;
      background: $color-positive;
      border-color:$color-positive;
    }
    outline:none;
    &:hover,&:focus{
      color:$color-white;
      background: $color-brandsecondary;
      border-color:$color-brandsecondary;
    }
  }
}