.secondarynav-component {
  box-sizing: content-box;
  display: flex;
  flex-flow: row nowrap;
  height:4.8rem;
  .nav-item{
    margin:0 1.9rem;
    a{
      outline:none;
      display: flex;
      height: 100%;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      font-size: 14px;
      line-height: 16px;
      text-align: center;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      text-decoration:none;
    }
  }
  &-color-default{
    background: $color-gray1;
    box-shadow: inset 0px -1px 0px $color-gray3, inset 0px 1px 0px $color-gray3;
    .nav-item{
      a{
        color:$color-gray4;
      }
    }
    .nav-item__selected a, .nav-item a:hover, .nav-item a:focus{
      box-shadow: inset 0px -6px 0px $color-brandprimary;
      color:$color-brandprimary;
    }
  }
  &-color-secondary{
    background: $color-gray5;
    .nav-item{
      a{
        color:$color-gray3;
      }
    }
    .nav-item__selected a, .nav-item a:hover, .nav-item a:focus{
      box-shadow: inset 0px -6px 0px $color-brandtertiary-highlight;
      color:$color-white;
    }
  }
}