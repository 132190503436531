.popper {
  cursor: pointer;

  &__toggle {
    &:hover, &:focus {
      outline: $shadow-accessible-outline;
      color:$color-brandprimary;
    }
    &--disabled {
      cursor: default;
      pointer-events: none;
    }
  }

  &__content {
    border: 1px solid $color-gray3;
    border-radius: 4px;
    background: $color-gray1;
    z-index:$z-index-poppermenu-dropdown ; // needed to appear above icons below the popout menu

    &.arrow {
      margin-top: 8px;
    }
  }

  &__icon {
    cursor: pointer;
  }

  &__menu {
    min-width: 14.2rem;
    display: flex;
    flex-direction: column;
    margin: 4px 0;
  }

  &__arrow {
    width: 0;
    height: 0;
    position: absolute;
    border-style: solid;
    border-width: 0 8px 8px 8px;
    border-color: transparent transparent $color-gray3 transparent;
    top: -8px;

    &-inner {
      width: 0;
      height: 0;
      position: absolute;
      border-style: solid;
      border-width: 0 8px 8px 8px;
      border-color: transparent transparent $color-gray1 transparent;
      top: 2px;
      left: -8px;
    }
  }
}
