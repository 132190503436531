.menu-item {
  width: 100%;
  height: 4.8rem;
  line-height: 4.8rem;
  outline: none;
  padding: 0;
  border: none;
  text-align: left;
  z-index: $z-index-menu-item;

  span {
    font-size: 1.6rem;
    padding: 0 2.4rem;
  }
  cursor: pointer;

  &--active, &:focus {
    font-weight: 700;
    color: $color-positive;
    box-shadow: inset 6px 0px 0px $color-positive;
    background: $color-brandtertiary-highlight;
  }

  &--disabled {
    color: $color-gray3;
    pointer-events: none;
  }

  &:hover {
    background: $color-brandtertiary-highlight;
  }
}
