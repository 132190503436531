.modal__background {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  z-index: $z-index-modal-background;

  background: $color-popupoverlay;
  outline: none;

  &.open {
    overflow-x: hidden;
    overflow-y: auto;
  }

  &.closed {
    display: none;
  }

}