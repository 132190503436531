.loading-gradient {
  height: 10rem;
  width: 10rem;

  &__svg {
    animation: 2s linear infinite both loading-gradient__svg;
  }

  &__svg-circle {
    animation: 1.5s ease-in-out infinite both loading-gradient__svg-circle;
    fill: transparent;
    stroke-dasharray: 275;
    stroke-linecap: round;
    stroke-width: 1rem;
    transform-origin: 50% 50%;
  }
}

@keyframes loading-gradient__svg {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg)
  }
}

@keyframes loading-gradient__svg-circle {
  0%, 25% {
    stroke-dashoffset: 225;
    transform: rotate(0);
  }
  
  50%, 75% {
    stroke-dashoffset: 75;
    transform: rotate(45deg);
  }
  
  100% {
    stroke-dashoffset: 225;
    transform: rotate(360deg);
  }
}
