.tabnavcontainer-component{
  box-sizing: content-box;
  display:flex;
  flex-flow:row wrap;
}
.tabnav-component{
  flex:0 0 17.2rem;
  display:flex;
  flex-flow:column;
  margin:0.1rem;
  align-items:center;
  justify-content: center;
  padding: 2.8rem 2rem;
  background-color:$color-gray1;
  box-shadow: inset 0 -0.1rem 0 $color-gray3;
  width:17.2rem;
  height:11.3rem;
  overflow:hidden;
  outline: none;
  & .tabnav-component--icon svg{
    width:2.4rem;
    height:2.4rem;
    color: $color-brandsecondary;
  }
  & .tabnav-component--label{
    padding-top:1.6rem;
    text-align: center;
    letter-spacing: 0.05rem;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1.6rem;
    line-height: 1.6rem;
    max-height:1.6rem;
    overflow:hidden;
    color: $color-brandsecondary;
  }
  &.tabnav-component__selected, &:hover, &:focus {
    background-color:$color-brandtertiary-highlight;
    cursor:pointer;
    box-shadow: inset 0 -0.6rem 0 $color-brandprimary;
  }
  &.tabnav-component__disabled{
    &:hover{
      cursor:initial;
      background-color:$color-gray1;
      box-shadow: inset 0 -0.1rem 0 $color-gray3;
    }
    & .tabnav-component--icon svg{
      color: $color-gray4;
    }
    & .tabnav-component--label{
      color: $color-gray4;
    }

  }
}
