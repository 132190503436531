.sort-label {
  display: inline-flex;
  cursor: pointer;
  outline: none;
  &:focus, &  :hover {
    outline:  $shadow-accessible-outline;
  }

  &__label {
    flex: 1 0 auto;
    font-family: $font-family;
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    line-height: 20px
  }

  &__icon {
    width: 2rem;
    flex: 0 0 auto;
    padding: 0 0.5rem;

    .sort-icon {
      color: $color-brandprimary;
    }
  }
}
