// Based on REM being 10px.
$button-height: 4.8rem;
$button-border-width: 0.2rem;

.button-component {
  font-size: 1.6rem;
  line-height: 1.6rem;
  letter-spacing: 0.05rem;
  text-transform: uppercase;
  font-weight: bold;
  height: $button-height;
  user-select: none;
  outline:none;

  &-button {
    border-radius: 0.8rem;
    border: $button-border-width solid transparent;
    padding: 0 calc( #{$button-height} - ( #{$button-border-width} * 2 ));
    height: calc( #{$button-height} - ( #{$button-border-width} * 2 ));
  }

  &-primary {
    color: $color-white;
    background-color: $color-brandprimary;
    border-color: $color-brandprimary;

    &:hover, &.button-component--hover, &:focus {
      color: $color-white;
      background-color: $color-brandsecondary;
      border-color: $color-brandsecondary;
    }
    &.button-component--selected {
      background-color: $color-positive;
      border-color: $color-positive;
    }
    &:visited {
      color: $color-white;
    }
    // Put disabled last so it overwrites the other css and makes background/foreground correct
    &:disabled {
      color: $color-gray4;
      background-color: $color-gray1;
      border-color: $color-gray3;
    }
  }

  &-secondary {
    color: $color-brandprimary;
    background-color: transparent;
    border-color: $color-brandprimary;
    &:hover, &.button-component--hover, &:focus {
      color: $color-brandsecondary;
      border-color: $color-brandsecondary;
    }
    &.button-component--selected {
      color: $color-positive;
      border-color: $color-positive;
      &:visited {
        color: $color-positive;
      }
    }
    &:visited {
      color: $color-brandprimary;
    }
    // Put disabled last so it overwrites the other css and makes background/foreground correct
    &:disabled {
      color: $color-gray5;
      border-color: $color-gray3;
      background-color: $color-gray2;
    }
  }

  &-tertiary {
    color: $color-white;
    background-color: $color-gray5;
    border-color: $color-gray5;
    &:hover, &.button-component--hover, &:focus {
      background-color: $color-brandsecondary;
      border-color: $color-brandsecondary;
    }
    &.button-component--selected {
      background-color: $color-black;
      border-color: $color-black;
    }
    &:visited {
      color: $color-white;
    }
    // Put disabled last so it overwrites the other css and makes background/foreground correct
    &:disabled {
      background-color: $color-gray1;
      border-color: $color-gray3;
      color: $color-gray4;
    }
  }

  &-light {
    color: $color-brandsecondary;
    background-color: $color-gray1;
    border-color: $color-gray1;
    &:hover, &.button-component--hover, &:focus {
      background-color: $color-brandtertiary-highlight;
      border-color: $color-brandtertiary-highlight;
    }
    &.button-component--selected {
      background-color: $color-gray2;
      border-color: $color-gray2;
    }
    &:visited {
      color: $color-brandsecondary;
    }
    // Put disabled last so it overwrites the other css and makes background/foreground correct
    &:disabled {
      background-color: $color-white;
      border-color: $color-gray1;
      color: $color-gray3;
    }
  }

  &-text {
    color: $color-brandprimary;
    border: none;
    text-decoration: underline;
    background-color: transparent;
    &:hover, &.button-component--hover, &:focus  {
      color: $color-brandsecondary;
    }
    &.button-component--selected{
      color: $color-positive;
    }
    &:visited {
      color: $color-brandprimary;
    }
  }

  &-text-light {
    color: $color-gray1;
    border: none;
    text-decoration: underline;
    background-color: transparent;
    &:hover, &.button-component--hover, &:focus  {
      color: $color-brandtertiary-highlight;
    }
    &.button-component--selected{
      color: $color-positive;
    }
    &:visited {
      color: $color-gray3;
    }
  }

  &-icon {
    height: 3.2rem;
    width: 3.2rem;
    color:$color-white;
    background-color: $color-brandprimary;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    & svg{
      width: 1.6rem !important;
      height: 1.6rem !important;
      color:$color-white;
    }
    &:hover, &.button-component--hover, &:focus {
      background-color: $color-brandsecondary;
    }
    &.button-component--selected {
      background-color: $color-positive;
    }
    &:visited {
      color: $color-white;
    }
    // Put disabled last so it overwrites the other css and makes background/foreground correct
    &:disabled {
      background-color: $color-gray4;
    }
  }

  &-icontext {
    & svg{
      width: 1.6rem !important;
      height: 1.6rem !important;
      color: $color-brandprimary;
      margin-right: 1rem;
    }

    &:hover svg, &.button-component--hover svg, &:focus svg {
      color: $color-brandsecondary;
    }
    &:disabled svg {
      color: $color-gray3;
    }
    &.button-component--selected svg{
      color: $color-positive;
    }
  }

  &-link {
    &:hover {
      text-decoration: none;
    }
    line-height: calc(#{$button-height} / 1.2);
    display: inline-block;
    text-decoration: none;
    vertical-align: middle;
  }

  &.button-component--disabled {
    color: $color-gray4;
    background-color: $color-gray1;
    border-color: $color-gray3;
    pointer-events: none;

    &.button-component-text,
    &.button-component-text-light {
      background: none;
    }

    &.button-component-text-light {
      color: $color-gray3;
    }
  }
}
