td.table-cell {
  padding: 0 0 0 2.4rem;
  box-sizing: border-box;

  font-family: $font-family;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
}
