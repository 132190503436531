.table-row {
  .table-cell {
    // column holding the expand/collapse icon
    &.expand-control {
      .control-icon {
        color: $color-brandprimary;
        width: 24px;
      }
    }
    // spacer column for rows that don't expand, but that should still align with their siblings who
    // do
    &.expand-spacer {
    }
  }
}
