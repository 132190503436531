$slider-transition-duration:  all 0.3s ease-in-out;

.slider-component * {
  box-sizing: border-box;
}

.slider-component{
  box-sizing: border-box;
  display: flex;
  position:relative;
  flex-flow:column;
  outline: none;
  // Add margin on left and right of element to prevent
  // slider button from leaving div when expanded
  margin-right: calc( 1.6 * #{$slider-radius});
  margin-left: calc( 1.6 * #{$slider-radius});
  &--track{
    width:100%;
    height: calc( 2.6 * #{$slider-radius});
    display:flex;
    flex-flow:row ;
    align-items:center;
    justify-content:center;

    &--bar{
      height:1.6rem;
      flex:1; // width and flex-basis decide the size of the bars.
      transition: $slider-transition-duration;

      &-active{
        border-top-left-radius: 2rem;
        border-bottom-left-radius: 2rem;
        background: $gradient_option;
      }
      &-inactive{
        border-top-right-radius: 2rem;
        border-bottom-right-radius: 2rem;
        background: $color-gray2;
      }
    }
    &__disabled{
      .slider-component--track--bar-active{
        background: $color-gray2;
      }
    }
  }

  &__element{
    margin-left: calc( -1.3 * #{$slider-radius} );
    display:inline-flex;
    flex-flow:column nowrap;
    align-items: center;
    flex-basis: 100%; // make sure long step labels end up the same width as shorter step labels.
    cursor: pointer;
    z-index:10;
    position:absolute;
    transition: $slider-transition-duration;

    &--tooltip{
      padding:0 24px;
      user-select: none;
    }

    &--icon{
      background: $color-white;
      border: $slider-border-width solid $color-positive;
      border-radius: 100%;
      display: block;
      // Height, width, and margin combine to give the hover effect of making the slider handle larger on hover.
      height: calc( 2 * #{$slider-radius} );
      width: calc( 2 * #{$slider-radius} );
      margin: calc( #{$slider-icon-hover-expand-margin-allowance} * #{$slider-radius} );
      outline: none;
      transition: $slider-transition-duration;
      border-color: $color-positive;
      box-shadow: 0px 0px 0.8rem $shadow-black-lighter;
      z-index:10;

      &:hover {
        // We took the margin set in the non-hover state away and added to both the width and height.
        // It's *2 as margin is on both sides of element.
        height: calc( (2 * #{$slider-radius}) + ( #{$slider-icon-hover-expand-margin-allowance} * #{$slider-radius} *2 ));
        width: calc( (2 * #{$slider-radius}) + ( #{$slider-icon-hover-expand-margin-allowance} * #{$slider-radius} *2 ));
        margin: 0;
        box-shadow: 0px 0.4rem 0.8rem $shadow-black;
      }
      &__disabled {
        border-color: $color-gray3;
      }
    }
  }
}
