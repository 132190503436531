.radiobuttongroup-component {
  box-sizing: content-box;
  display:flex;
  align-items:flex-start;
  justify-content:center;
  flex-flow:column;
  &--size-large{
    font-size:1.6rem;
    line-height:2rem;
    padding:5px 0;
  }
  &--size-small{
    font-size:1rem;
    line-height:1.5rem;
    padding:2px 0;
  }
}


.radiobutton-component{
  box-sizing: content-box;
  transition: all 0.3s ease-in-out;
  display: flex;
  align-items:center;
  justify-content: center;
  color: $color-brandsecondary;
  outline:none;
  &:focus, &:hover{
    outline:  $shadow-accessible-outline;
  }
  &__label{
    cursor:pointer;
    display:flex;
    align-items:center;
    justify-content:center;
    
    &-position-right{
      flex-flow:row-reverse nowrap;
      span{
        padding-left:0.8rem;
      }
    }
  }
  &__icon{
    &--hidden{
      display:none;
    }
  }
  &--selected{
    font-weight: 500;
    svg {
      transition: all 0.3s ease-in-out;
      color: $color-positive;
    }
  }
  &--disabled{
    color: $color-gray4;
  }
}