.rule-component{
  display: block;
  clear:both;
  &__size-large{
    height:0.4rem;
    background: $color-gray4;
    color: $color-gray4;
  }
  &__size-small{
    height:0.1rem;
    background: $color-gray3;
    color: $color-gray3;
  }
}