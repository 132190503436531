.tag {
  display: inline-flex;
  align-items: center;
  height: 3.2rem;
  background-color: $color-brandprimary;
  border: 2px solid transparent;
  border-radius: 0.8rem;
  color: $color-white;
  padding: 0 .8rem;
  cursor: pointer;

  &:hover,
  &:focus {
    outline: none;
    background-color: transparent;
    color: $color-brandprimary;
    border: 2px solid $color-brandprimary;

    svg{
      color: $color-brandprimary;
    }
  }

  &__close_icon_container {
    padding-left: .8rem;

    svg {
      color:$color-white;
    }
  }
}
