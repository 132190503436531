.topbar {
  display: flex;
  align-content: center;
  justify-content: space-between;
  position: relative;
  height: 5.6rem;
  width: 100%;
  min-width: 40rem;
  z-index: 1000;
  background-color: $color-white;
  box-shadow: inset 0px -1px 0px $color-gray3;


  &__logo {
    display: flex;
    margin-left: 3.2rem;
    align-items: center;
    
    svg {
      height: 23.95px;
      width: 110.86px;
      .kubra {
        fill: $color-gray5;
      }
      .tri-top {
        fill: $kubra-blue;
      }
      .tri-mid {
        fill: $kubra-green;
      }
      .tri-bottom {
        fill: $color-gray5;
      }
    }
  }

  &__right {
    display: flex;
  }

  &__menu {
    cursor: pointer;
    width: 5.3rem;
    height: 5.5rem;
    line-height: 5.5rem;
    text-align: center;
    border-left: 1px solid $color-gray3;
    svg {
      margin: 0;
    }

    .popper__content {
      border-radius: 0;
      border-right: 0;
    }
  }

  .selected-client {
    margin-right: 1.6rem;
    align-self: center;
    
    font-size: 1.4rem;
    padding: .2rem 1.6rem;
    background-color: $color-gray1;
    border: 1px solid $color-gray3;
    border-radius: 10rem;

    svg {
      color: $color-gray5;
      margin-right: .8rem;
    }
    .kubra-admin-ico {
      color: $yellow;
    }
  }
}
