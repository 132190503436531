.accordion {
  box-sizing: content-box;
  cursor: pointer;
  min-width: 20rem;
  max-width: 60.4rem;
  border: 1px solid transparent;
  border-bottom: 1px solid $color-gray3;

  .accordion-label {
    outline: none;
    display: flex;
    align-items: center;
    user-select: none;

    p, span { 
      font-weight: 900;
      font-size: 1.6rem;
      line-height: 2rem;
      color: $color-brandsecondary;
    }

    svg {
      padding: 0 1.6rem;
      color: $color-brandprimary;
    }
  }

  .accordion-container {
    outline: none;
    display: block;

    .accordion-label {
      padding-right: 1.6rem;
      height: 6.4rem;
    }
  }

  .accordion-contents {
    cursor: default;
    height: 0;
    padding-left: 5.2rem;
    overflow: hidden;
    transition: all 0.3s ease-in-out;

    p { 
      margin: 0;
      padding-right: 1.6rem;
    }

    &.accordion-open {
      height: auto;
      padding-bottom: 1.6rem;
    }
  }

  &.accordion-open {
    background: $color-gray1;
    border: 1px solid $color-gray3;
  }

  // apply hover styles if not disabled
  &:not(.accordion-disabled) {
    :hover, :focus  {
      background-color:$color-gray1;
    }
  }
}

// ACCORDION GROUP
.accordion-group {
  .accordion {
    &.accordion-open {
      border-top: none;
      
        &:not(:first-child) {
          .accordion-label {
            // prevent text jump on opening
            padding-top: 1px;
        }
      }

      // only first accordion in group gets visible border on top
      &:first-child {
        border-top: 1px solid $color-gray3;
      }
    }
  }
}

// DISABLED
.accordion {
  &.accordion-disabled {
    cursor: default;
    background: $color-gray1;
    background-image: repeating-linear-gradient(
        45deg,
        transparent,
        transparent .5rem,
        rgba(255,255,255,.5) .5rem,
        rgba(255,255,255,.5) 1rem,
      );

    svg { color: $color-gray5; }

    .accordion-label span, .accordion-label p {
      color: $color-gray4;
    }
  }
}
