.alert-component{
  display:flex;
  flex-flow:row nowrap;
  justify-content: space-between;
  align-items:center;
  padding:2rem 2.4rem;
  color: $color-white;
  box-shadow: 0 0.4rem 0.8rem $shadow-black;
  border-radius: 0.8rem;
  opacity:1;
  max-height: 100px;
  
  &.alert-component--status-info {
    background: $color-brandsecondary;
    a.alert-component__hyperlink:focus{
      background-color:rgba(255,255,255,0.3);
    }
  }
  &.alert-component--status-warning {
    background: $color-warning;
  }
  &.alert-component--status-error {
    background: $color-error;
  }
  &.alert-component--status-success {
    background: $color-positive;
  }
  & .alert-component__icon{
    padding-right:2.4rem;
  }
  & .alert-component__icon svg{
    color: $color-white;
    width:2.4rem;
    height:2.4rem;
  }

  & a.alert-component__hyperlink{
    margin-right:2.4rem;
    color: $color-white;
    &:focus{
      outline: $shadow-accessible-outline;
    }
  }

  & .alert-component__close{
    background: none;
    border:none;
    outline:none;
    &:focus{
      outline: $shadow-accessible-outline;
    }
    svg{
      color: $color-white;
      width:1.6rem;
      height:1.6rem;
    }
    & svg:nth-child(1){
      display:none;
    }
    &:hover svg:nth-child(1), &:focus svg:nth-child(1){
      display:inherit;
    }
    &:hover svg:nth-child(2), &:focus svg:nth-child(2){
      display:none;
    }
  }
  // Positioning of the status bar - turns it into a snackbar.
  &.alert-component--position-top, &.alert-component--position-bottom, &.alert-component--position-center{
    position: fixed;
    width:75%;
    min-width: 30rem;
    margin:0;
    left:50%;
    transform: translate(-50%, 0);
    transition: transform 0s ease-out;
  }
  &.alert-component--position-top{
    top:0;
  }
  &.alert-component--position-center{
    top:50%;
    transform: translate(-50%, -50%);
  }
  &.alert-component--position-bottom{
    bottom:0;
  }
  &__text{
    display:flex;
    flex-flow:column nowrap;
    flex:1 1;
    padding:0 2.4rem 0 0;
    font-size:1.8rem;
    font-weight:900;
    line-height:2.2rem;
  }
  // Has to be bottom rule so it overrides settings set elsewhere in rules.
  &--closed{
    transition: transform 8s ease-out, opacity 1s ease-out !important;
    transform: translateX(-9999px) !important; // moves left.
    opacity:0;
    position: absolute;
  }
}