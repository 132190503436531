.calendar-component__picker{
  margin:2rem 0 ;
  &__label{
    display:flex;
    align-items: center;
    justify-content: center;
  }
  &__buttons{
    display:flex;
    align-items: center;
    justify-content: center;
    margin:2rem 0 0 0;
    button{
      margin:0 1rem;
    }
  }
  &__rule{
    margin:2.2rem 0;
  }
  &__popperContent{
    background-color: $color-white;
  }
}
.calendar-component__time{
  margin:0 2rem;
  display:flex;
  align-items: center;
  justify-content: center;
  input{
    box-sizing: border-box;
    height:4.8rem;
    width: 7.2rem;
    text-align:right;
    line-height:2.4rem;
    padding: 14px 15px;
    border: 1px solid $color-gray3;
  }
  &__hour{
    &--error{
      input {
        box-shadow: 0 0 3px $color-error;
      }
    }
  }
  &__minute{
    &--error{
      input {
        box-shadow: 0 0 3px $color-error;
      }
    }
  }
  &__period{
    display:flex;
    flex-flow:column;
  }
  &__divider16{
    width:1.6rem;
  }
  &__divider12{
    width:1.2rem;
  }
}

.calendar-component__date{
  margin:0 2rem;
  &__header{
    display: flex;
    flex-flow:row nowrap;
    justify-content: space-between;
    align-items:center;
    padding-bottom:2.2rem;
    &-month{
      font-weight:900;
      font-size:1.8rem;
      line-height:2.2rem;
    }
    &-icons-start, &-icons-end{
      display: flex;
      flex-flow:row nowrap;
      div:first-child{
        margin-right:2.2rem;
      }
    }
    &-icon{
      outline: none;
      &:focus, &:hover{
        outline:  $shadow-accessible-outline;
      }
      svg:first-child{
        display:none;
      }
      svg:last-child{
        display:block;
      }
      &:hover{
        svg:first-child{
          display:block;
        }
        svg:last-child{
          display:none;
        }
      }
    }
  }

  &__week{
    display:flex;
    flex-flow:row nowrap;
    font-size:1.4rem;
  }
  &__itementry,  &__itementry-fg {
    display:flex;
    align-items:center;
    justify-content:center;
    width:4rem;
    height:4rem;
    line-height:2.4rem;
  }
  &__itementry{
    outline: none;
    &:hover, &:focus{
      outline: $shadow-accessible-outline;
    }
  }
  &__itementry-fg {
    &--currentmonth{
      color: $color-black;
    }
    &--hover{
      border-radius:50%;
      background-color:$color-brandprimary;
      color:$color-white;
      font-weight:500;
      div {
        color:$color-white;
      }
    }
    &--selected{
      border-radius:50%;
      color:$color-white;
      background-color:$color-positive;
      font-weight:500;
    }
  }
}
.calendar-component__day{
  background-clip: padding-box;
  color:$color-gray3;
  &__range-start{
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;
    background-color: $color-brandtertiary-highlight;
  }
  &__range-end{
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
    background-color: $color-brandtertiary-highlight;
  }
  &__range-middle{
    border-radius:initial;
    background-color: $color-brandtertiary-highlight;
  }
}